import React from 'react';

import { Header } from 'reaxl-header-ford';

export default function FordHeader() {

    const fordLogo = {
        src: 'https://www.autotrader.com/content/static/img/partner/ford/ford-header-logo.svg',
        alt: 'Ford Blue Advantage logo',
        width: '199',
        height: '48',
    };
    const atcFordLogo = {
        src: 'https://www.autotrader.com/content/static/img/partner/ford/ford-header-atc-logo.svg',
        alt: 'Autotrader logo',
        width: '88',
        height: '22',
    };

    return (
        <Header
            atcFordLogo={atcFordLogo}
            fordLogo={fordLogo}
        />
    );
}
